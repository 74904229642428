import Vue from 'vue'
import VueRouter from 'vue-router'
import {Message} from 'element-ui';

import Layout from '../components/Layout.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/Home',
    name: 'Home',
    alias: '/',
    component: () => import("@/views/Home.vue"),
}, {
    path: "/GeneralSituation",
    component: () => import("@/views/generalSituation/generalSituation.vue"),
    name: "GeneralSituation",
}, {
    path: "/RepairProcess",
    component: () => import("@/views/repairProcess/repairProcess.vue"),
    name: "RepairProcess",

}, {
    path: '/RepairProcessDetails',
    component: () => import("@/views/repairProcess/repairProcessDetails.vue"),
    name: 'RepairProcessDetails'
}, {
    path: '/CulturalRelicsImitation',
    component: () => import("@/views/culturalRelicsImitation/culturalRelicsImitation.vue"),
    name: 'CulturalRelicsImitation'
}, {
    path: '/PreventiveProtection',
    component: () => import("@/views/preventiveProtection/preventiveProtection.vue"),
    name: 'PreventiveProtection'
},{
    path: '/storeroom',
    component: () => import("@/views/digitize/storeroom.vue"),
    name: 'Storeroom',
},{
    path: '/environment',
    component: () => import("@/views/digitize/environment.vue"),
    name: 'Environment',
},{
    path: '/archaeology',
    component: () => import("@/views/digitize/archaeology.vue"),
    name: 'Archaeology',
},{
    path: '/declare',
    component: () => import("@/views/digitize/declare.vue"),
    name: 'Declare',
}, {
    path: '/RepairMaterials',
    component: () => import("@/views/repairMaterials/repairMaterials.vue"),
    name: 'RepairMaterials'
}, {
    path: '/Achievement',
    component: () => import("@/views/Home.vue"),
    name: 'Achievement'
}, {
    path: '/ContactUs',
    component: () => import("@/views/contactUs/contactUs.vue"),
    name: 'ContactUs'
}, {
    path: '/ExpertDemonstration',
    component: () => import("@/views/expertDemonstration/expertDemonstration.vue"),
    name: 'ExpertDemonstration',
}, {
    path: '/refresh',
    name: 'refresh',
    component: () => import("@/views/refresh.vue"),
},]

const router = new VueRouter({
    mode: 'history', // 去掉url中的#
    routes
});
// 切换路由，页面滚动到顶部
router.afterEach(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
})

export default router
